import $ from 'jquery';
window.$ = window.jQuery = $;
import 'slick-carousel'

export const initSlick = () => {
  $(() => {
    const technologiesSlider = $('.technologies__list');
    const workSlider = $('.work-advantages');
    const teamSlider = $('.team-members');
    const vacanciesSlider = $('.vacancies-list');
    const ipadSize = 1024;
    const tabletSize = 768;
    const mobileSize = 376;
    let windowWidth;

    const slider = (width, slider) => {
      slider == technologiesSlider &&
        (width <= mobileSize && !slider.hasClass('slick-slider')
          ? slider.slick({
              slidesToShow: 2,
              slidesToScroll: 2,
              dots: true,
              arrows: false,
              autoplay: true,
              autoplaySpeed: 5000,
            })
          : null,
        width > mobileSize && slider.hasClass('slick-slider') ? slider.slick('unslick') : null);

      slider == teamSlider &&
        (width <= mobileSize && !slider.hasClass('slick-slider')
          ? slider.slick({
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false,
              arrows: false,
              autoplay: true,
              autoplaySpeed: 5000,
            })
          : null,
        width > mobileSize && slider.hasClass('slick-slider') ? slider.slick('unslick') : null);

      slider == workSlider &&
        (slider.hasClass('slick-slider') && slider.slick('unslick'),
        width > ipadSize && slider.hasClass('slick-slider') && slider.slick('unslick'),
        width <= ipadSize &&
          width > tabletSize &&
          slider.slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            dots: false,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 5000,
          }),
        width <= tabletSize &&
          width > mobileSize &&
          slider.slick({
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: false,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 5000,
          }),
        width <= mobileSize &&
          slider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 7000,
          }));

      slider == vacanciesSlider &&
        (slider.hasClass('slick-slider') && slider.slick('unslick'),
        width > ipadSize && slider.hasClass('slick-slider') && slider.slick('unslick'),
        width <= ipadSize &&
          width > tabletSize &&
          slider.slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            dots: false,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 5000,
          }),
        width <= tabletSize &&
          width > mobileSize &&
          slider.slick({
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: false,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 5000,
          }),
        width <= mobileSize &&
          slider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 7000,
          }));
    };

    windowWidth = window.innerWidth;
    slider(windowWidth, technologiesSlider);
    slider(windowWidth, workSlider);
    slider(windowWidth, teamSlider);
    slider(windowWidth, vacanciesSlider);

    $(window).on('resize', () => {
      windowWidth = window.innerWidth;
      slider(windowWidth, technologiesSlider);
      slider(windowWidth, workSlider);
      slider(windowWidth, teamSlider);
      slider(windowWidth, vacanciesSlider);
    });
  });
};