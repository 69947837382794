
export const initHeader = () => {
  const body = document.querySelector('body'),
         nav = document.querySelector('.header__nav'),
        menu = document.querySelector('.header__mobile-menu'),
   menuLinks = document.querySelectorAll('.header__menu');

  menu.addEventListener('click', ()=> {
    menu.classList.toggle('active')
    nav.classList.toggle('active')
    body.classList.toggle('lock')
  }, {passive: true})

  menuLinks.forEach(link => link.addEventListener('click', ()=> closeMenu(), {passive: true}))

  const closeMenu = () => {
    menu.classList.remove('active')
    nav.classList.remove('active')
    body.classList.remove('lock')
  }
}